// Browser tests

window.device = {};

( function( window, document, undefined ) {

	/**
	 * detect IE
	 * returns version of IE or false, if browser is not Internet Explorer
	 */
	function detectIE() {
	  var ua = window.navigator.userAgent;

	  // Test values; Uncomment to check result …

	  // IE 10
	  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
	  
	  // IE 11
	  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
	  
	  // Edge 12 (Spartan)
	  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
	  
	  // Edge 13
	  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

	  var msie = ua.indexOf('MSIE ');
	  if (msie > 0) {
	    // IE 10 or older => return version number
	    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	  }

	  var trident = ua.indexOf('Trident/');
	  if (trident > 0) {
	    // IE 11 => return version number
	    var rv = ua.indexOf('rv:');
	    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	  }

	  var edge = ua.indexOf('Edge/');
	  if (edge > 0) {
	    // Edge (IE 12+) => return version number
	    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	  }

	  // other browser
	  return false;
	}

	let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/),
			isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
			isChrome = !!window.chrome && !!window.chrome.webstore,
			isEdge = window.navigator.userAgent.indexOf("Edge") > -1,
			isIPad = /iPad/i.test( navigator.userAgent ),
			ieVersion = detectIE(),
			browsers = [];

	if ( isSafari ) {
		browsers.push( 'is-safari' );
		device.isSafari = true;
		document.documentElement.classList.add( 'svg-relative-origins' );
	}

	if ( isFirefox ) {
		browsers.push( 'is-firefox' );
		device.isFirefox = true;
		document.documentElement.classList.add( 'svg-relative-origins' );
	} else {
		browsers.push( 'not-firefox' );
		device.isFirefox = false;
	}

	if ( isChrome ) {
		browsers.push( 'is-chrome' );
		device.isChrome = true;
	} else {
		browsers.push( 'not-chrome' );
		device.isChrome = false;
	}

	if ( isEdge ) {
		browsers.push( 'is-edge' );
		device.isEdge = true;
	} else {
		browsers.push( 'not-edge' );
		device.isEdge = false;
	}

	if ( isIPad ) {
		browsers.push( 'is-ipad' );
		device.isIPad = true;
	} else {
		browsers.push( 'not-ipad' );
		device.isIPad = false;
	}

	if ( ieVersion ) {
		browsers.push( 'is-ie' );
		browsers.push( 'is-ie-' + ieVersion );
		device.isIE = true;
		device.ieVersion = ieVersion;
	} else {
		browsers.push( 'is-not-ie' );
		device.isIE = false;
		device.ieVersion = false;
	}

	if ( browsers.length ) {
		for (var i = 0, len = browsers.length; i < len; i++) {
			let _this = browsers[i];
			document.documentElement.classList.add( _this );
		}
	}

} )(  window, document );
